@import '~antd/dist/antd.css';
.App {
  background-color: #202529;
  height:100%;
}

.App div{
    background-color: #202529 !important;
    color: #fff !important;
}
.site-card-wrapper{
    padding: 20px;
}

.my-line{
    border: 1px solid rgb(62,66,69) !important;
}

.ant-collapse-content{
    border-top: 1px solid rgb(62,66,69) !important;
}